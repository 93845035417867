import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../Constants";

const ChangePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [resetPassword, setResetPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const OnHandleChange = (e) => {
    setResetPassword((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (resetPassword.newPassword === resetPassword.confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [resetPassword]);

  const onClickLogo = () => {
    navigate("/");
  };

  const OnSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const newPass = {
      token: token,
      password: resetPassword.confirmPassword,
    };
    const res = await axios.post(`${BASE_URL}/Auth/ConfirmPassword, newPass`);
    if (res.status === 200) {
      setLoading(false);
      toast.success(
        "If UserName matches with our account, You will recieve a link to reset the password in registered Email."
      );
      navigate(`/`);
    }
    setLoading(false);
  };
  return (
    <div className="h-full flex bg-black">
      <div className="sm:w-1/2 lg:w-2/3 sm:block hidden relative">
        <img
          className="loginImg h-screen -ml-3 object-cover"
          src="loginPicture.svg"
        />
        <div className="absolute top-1/4 lg:left-28 left-12 pr-5 text-white flex flex-col gap-6">
          <label className="lg:text-4.5xl text-2xl lg:leading-12 leading-8">
            Unlock seamless access to all your web applications
            <br /> with our comprehensive solution
          </label>
          <label className="lg:text-2xl text-base font-medium">
            Simplify your digital experience and enhance productivity by
            <br />
            managing and navigating diverse applications effortlessly from a
            <br />
            single hub.
          </label>
        </div>
      </div>
      <form
        className="flex flex-col w-1/3 px-20 pt-24 relative mr-4"
        onSubmit={OnSubmit}
      >
        <label className="text-neutral-500 text-base font-medium mb-16 border-l-4 pl-2 border-l-orange-600">
          Complex passwords provide a powerful defense against unauthorized
          access.
        </label>
        <div className="text-sm font-medium text-white pb-2">
          Enter New password
        </div>
        <div className="text-sm text-center mb-5">
          <input
            name="newPassword"
            type="password"
            placeholder="New password"
            className="w-full h-12 pl-5 text-xs placeholder-neutral-400 bg-white font-medium rounded-lg border border-blue-800 ring-1 ring-sky-200"
            value={resetPassword.newPassword}
            onChange={OnHandleChange}
          />
        </div>
        <div className="flex flex-col">
          <label for="email" className="text-sm font-medium text-white pb-2">
            Confirm Password
          </label>
          <input
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            className={`${
              passwordMatch !== true
                ? "border-red-500 focus:ring-2 focus:ring-red-500 ring-1 ring-red-500"
                : " focus:ring-2 focus:ring-sky-500 ring-1 ring-sky-200"
            } w-full h-12 pl-5 text-xs placeholder-neutral-400 bg-white font-medium rounded-lg border border-blue-800`}
            value={resetPassword.confirmPassword}
            onChange={OnHandleChange}
          />
          {passwordMatch !== true && (
            <p className="text-red-600 text-xs mt-2">Password Didn't Match</p>
          )}
          <button
            type="submit"
            className="bg-gradient-to-r flex justify-center items-center gap-2 from-red-800 disabled:opacity-40 to-orange-600 mt-4 text-white rounded-lg w-full h-14 font-semibold text-xs text-align-center"
            disabled={!passwordMatch || loading}
          >
            {loading && (
              <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Change Password
          </button>
        </div>
        <div className="flex justify-center gap-5 md:gap-8 items-center absolute bottom-8 right-1/2 transform -translate-y-1/2 translate-x-1/2">
          <label className="text-white text-xxs md:text-xs">Powered by</label>
          <img src="GamberaDynamicsLogo.svg" className="w-24 lg:w-28" />
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
