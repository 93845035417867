import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import msalInstance from "../Constants";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";

function Navbar() {
  // const navigation = [
  //   { name: "Home", href: "/", current: route === "/" },
  //   { name: "About", href: "/about", current: route === "/about" },
  //   { name: "Contact Us", href: "/contact", current: route === "/contact" },
  // ];
  const navigate = useNavigate();
  const { logout } = useContext(AppContext);
  const onLogoutClick = () => {
    handleGoogleLogout();
    msalInstance.logout();
    logout();
    navigate("/");
  };
  function handleGoogleLogout() {
    gapi.auth2
      ?.getAuthInstance()
      ?.signOut()
      .then(() => {
        console.log("User signed out successfully.");
        // Perform additional actions after sign-out if needed
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  }

  // const onHandleClick = () => {
  //   console.log("clicked");
  //   setAdmin(true);
  //   toast.success("Success! You are now in Admin Mode.");
  // };
  return (
    <div className="flex items-center h-[12vh] justify-between p-4 px-10 shadow-[0_2px_20px_-5px_rgba(0,0,0,0.3)] text-black">
      <button type="button">
        <div className="flex items-center">
          <img
            width={150}
            height={100}
            src="GdDarkLogo.svg"
            alt="Logo"
            className="mr-2"
          />
        </div>
      </button>
      {/* <ul className="lg:flex hidden gap-8 items-center text-sm font-medium h-full text-black">
        {navigation.map((n, index) => (
          <li key={index}>
            <div
              tabIndex="0"
              className="m-1 btn font-medium text-[13px] rounded-none px-0 leading-4"
            >
              <Link
                href={n.href}
                className={`py-2 ${
                  n.current
                    ? "border-b-4 text-blue-500"
                    : "hover:opacity-100 hover:text-blue-500"
                } font-medium text-[14px] border-0 hover:border-b-4 border-white text-black rounded-none px-0 leading-4`}
              >
                {n.name}
              </Link>
            </div>
          </li>
        ))}
      </ul> */}
      <div className="flex">
        {/* <button className="bg-white border border-blue-500 text-blue-500 px-4 py-2 rounded hover:border-blue-600 hover:text-blue-600">
          Call Us
        </button> */}
        <button
          onClick={() => onLogoutClick()}
          className="bg-white border ml-2 flex items-center  gap-2 border-red-500 text-red-500 px-4 py-2 rounded hover:border-red-600 hover:text-red-600"
        >
          <img src="/logout-icon.svg" alt="" className="w-4 h-4" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
}

export default Navbar;
