import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "./AppContext";
import { HashRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import ChangePassword from "./components/ChangePassword";
import Layout from "./components/Layout";
import LoginLayout from "./components/LoginLayout";
import { gapi } from "gapi-script";
import msalInstance, { Client_id, sanityClient } from "./Constants";
import HrChatbot from "./components/HrChatbot";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
function App() {
  const [sanityData, setSanityData] = useState();
  const fetchData = async () => {
    const query = `*[_type == "chatbot"][0]{
      footer{
        brandData{
          'logo':logo.asset->url,
          description,
          siteLinks[]{
            label,
            link
          }
        },
        quickLinks{
          title1,
          title1Links[]{
            name,
            link
          },
          title2,
          title2Links[]{
            name,
            link
          }
        },
        poweredByData{
          title,
          'logo':logo.asset->url,
          crText,
          subtitle
        }
      }
     }
  `;

    const resData = await sanityClient.fetch(query);
    setSanityData(resData);
  };
  useEffect(() => {
    fetchData();
    async function start() {
      try {
        await gapi.client.init({
          clientId: Client_id,
          scope: "",
        });
      } catch (error) {
        console.error("Error initializing Google client:", error);
      }
    }

    gapi.load("client:auth2", start);
    msalInstance.initialize();
  }, []);
  const authCtx = useContext(AppContext);
  // const isLoggedIn = true;
  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <>
      {isLoggedIn ? (
        <HashRouter>
          <Layout sanityData={sanityData}>
            <Routes>
              <Route path="/" element={<HrChatbot />} />
            </Routes>
            <ToastContainer />
          </Layout>
        </HashRouter>
      ) : (
        <HashRouter>
          <LoginLayout>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/resetPassword" element={<PasswordReset />} />
              <Route path="/changePassword/:id" element={<ChangePassword />} />
            </Routes>
          </LoginLayout>
        </HashRouter>
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </>
  );
}

export default App;
