import React from "react";
import { useNavigate } from "react-router-dom";

const LoginLayout = ({ children }) => {
  const navigate = useNavigate();
  const onClickLogo = () => {
    navigate("/");
  };
  return (
    <div className="relative">
      <div className="bg-black h-screen w-screen">
        <div className="absolute h-fit w-full sm:block hidden bg-black mb-20 overflow-hidden z-10">
          <div className="px-7 py-3 flex gap-1 items-center h-14 w-full">
            <button
              type="button"
              className="h-9 pr-1 flex gap-3 items-center cursor-pointer"
              onClick={onClickLogo}
            >
              <img src="GDLogo1.svg" className="h-9" alt="gd-rms_logo" />
            </button>
            <div className="flex flex-col text-white">
              <span className="text-sm font-medium">ChatSmart</span>
              <span className="text-xxxs text-end">Data Solutions</span>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
