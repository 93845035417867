import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { sanityClient } from "../Constants";

function Layout({ children, sanityData }) {
  return (
    <div className="">
      {" "}
      <Navbar />
      {children}
      {/* <Footer sanityData={sanityData} /> */}
    </div>
  );
}

export default Layout;
