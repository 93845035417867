import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "./Constants";
import { json } from "react-router-dom";

const AppContext = React.createContext({
  profile: null,
  isLoggedIn: false,
  isLoading: false,
  login: (profile) => {},
  logout: () => {},
  // setAdmin: () => {},
  // admin: false,
});

export const AppContextProvider = (props) => {
  const profileString = localStorage.getItem("user_profile");
  //  profileString ? JSON.parse(profileString) : null;
  const parseProfile = JSON.parse(profileString);

  const [profile, setProfile] = useState(
    profileString ? { email: parseProfile?.email } : null
  );
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(parseProfile?.email);
  const getUser = async (email) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL}/Auth/SingleSignOn?email=${email}`
      );
      if (res.data.active) {
        loginHandler(res.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  const checkToken = async () => {
    try {
      let token = gapi.auth?.getToken();
      let userProfile = getCookie("GDuserProfile");
      if (!profile?.token && token && token.id_token && token.access_token) {
        const accessToken = token.id_token;
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken.email) {
          getUser(decodedToken.email);
        }
      } else if (!profile?.token && userProfile) {
        const decodedToken = jwtDecode(userProfile);
        if (decodedToken.email) {
          await getUser(decodedToken.email);
        }
      }
    } catch (error) {
      console.error("Error checking token:", error);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const loginHandler = (userProfile) => {
    setProfile(userProfile);
    localStorage.setItem("user_profile", JSON.stringify(userProfile));
  };

  const logoutHandler = () => {
    setProfile(null);
    localStorage.removeItem("user_profile");
    document.cookie = "GDuserProfile=; Max-Age=-99999999;";
    window.location.href = "/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const contextValue = {
    profile: profile,
    isLoading: loading,
    isLoggedIn: !!profile,
    login: loginHandler,
    logout: logoutHandler,
    // setAdmin: setAdmin,
    // admin: admin,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
