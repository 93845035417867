import { PublicClientApplication } from "@azure/msal-browser";
// export const BASE_URL = "https://localhost:7176";
import { createClient } from "@sanity/client";

export const BASE_URL = "https://gd-data-api.azurewebsites.net";
export const BASE_PY_URL="https://gd-chatbot-api.azurewebsites.net";
// export const BASE_PY_URL = "http://127.0.0.1:3001";

export const Client_id =
  "875838615965-k4ui5vc23fdtgjaa5nvg4er82h2a3pe1.apps.googleusercontent.com";

export const Role = [
  { value: 100, label: "Super Admin" },
  { value: 200, label: "Admin" },
  { value: 300, label: "User" },
];

const msalConfig = {
  auth: {
    clientId: "10608ece-ac47-4208-b5ba-383d88675041",
    authority:
      "https://login.microsoftonline.com/bef898b5-25e5-4796-93ab-11f861b61208",
    // redirectUri: "http://localhost:3000",
    redirectUri: "https://obiactum-chatbot.azurewebsites.net",
  },
  // Additional configuration as needed
};

const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;

function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const client = createClient({
  projectId: "2cbfc689",
  dataset: "production",
  apiVersion: getCurrentDate(),
  token:
    "skGd1QIBB3LObYTLdnCUPbxPHsM2rJc5zMF9BYpT3EDuJ4soqN2LgF6jnVIvpOFseHxpxFUvmeFOVXrBMXaIeLEw9FLecvBUZRX9CXNFqcnRgpiisTk490txI58cmv1lOeNSv8mjn5xoi1sdY3CREniJCUTfi2AwHmkBBnNdksmeSzVVQufF",
  useCdn: false,
});

export { client as sanityClient };
