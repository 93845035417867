import React, { useContext, useEffect } from "react";
import { useState } from "react";
import msalInstance, { BASE_URL, Client_id } from "../Constants";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";

const Login = () => {
  const navigate = useNavigate();
  const [validate, setValidate] = useState({
    userName: false,
    password: false,
    passwordIncorrect: false,
    singleSignOnError: false,
    errorMessage: null,
  });
  const [user, setUser] = useState({ userName: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const authCtx = useContext(AppContext);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const responseGoogleSuccess = async (response) => {
    setLoading(true);
    authCtx.isLoading = true;
    try {
      authCtx.login(response.profileObj);
      console.log(response.profileObj, "sss");
      // const res = await axios.post(
      //   `${BASE_URL}/Auth/SingleSignOn?email=${response.profileObj.email}`
      // );
      // setLoading(false);
      // // if (res.data.resetPassword === true) {
      // //   navigate(`/changePassword/${res.data.id}`);
      // // } else {
      // if (res.data.active) {
      //   authCtx.login(res.data);
      //   setCookie("GDuserProfile", JSON.stringify(response.tokenId), 7);
      // } else {
      //   setValidate((pre) => ({
      //     ...pre,
      //     errorMessage: "This user is not active",
      //   }));
      // }
    } catch (err) {
      console.log(err.response.data.message, "err");
      setValidate((pre) => ({
        ...pre,
        errorMessage: err.response.data.message,
      }));
    } finally {
      setLoading(false);
      authCtx.isLoading = false;
    }
  };
  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  const responseGoogleFailure = (error) => {
    console.error("Login Failed:", error);
    // Handle failed login, e.g., display error message or redirect to login page
  };
  const onLoginClick = async (e) => {
    e.preventDefault();
    if (user.userName === "" || user.password === "") {
      setValidate((pre) =>
        user.userName === ""
          ? { ...pre, userName: true }
          : { ...pre, userName: false }
      );
      setValidate((pre) =>
        user.password === ""
          ? { ...pre, password: true }
          : { ...pre, password: false }
      );
    } else {
      setValidate({
        userName: false,
        password: false,
        passwordIncorrect: false,
      });
      setLoading(true);
      try {
        const res = await axios.post(`${BASE_URL}/Auth/login`, user);
        setLoading(false);
        // if (res.data.resetPassword === true) {
        //   navigate(`/changePassword/${res.data.id}`);
        // } else {
        if (res.data.active) {
          authCtx.login(res.data);
        }
      } catch (err) {
        setLoading(false);
        setValidate((pre) => ({ ...pre, passwordIncorrect: true }));
      }
    }
  };
  const loginClick = async (e) => {
    e.preventDefault();
    authCtx.login({ token: "login" });
  };
  const msSignIn = async () => {
    setLoading(true);
    try {
      await msalInstance.handleRedirectPromise(); // Handle redirects if necessary
      const response = await msalInstance.loginPopup({
        scopes: ["user.read"],
      });
      console.log(response, "res");
      authCtx.login(response.account);
      // const res = await axios.post(
      //   `${BASE_URL}/Auth/SingleSignOn?email=${response.account.username}`
      // );
      // setLoading(false);
      // // if (res.data.resetPassword === true) {
      // //   navigate(`/changePassword/${res.data.id}`);
      // // } else {
      // if (res.data.active) {
      //   authCtx.login(res.data);
      //   setCookie("GDuserProfile", JSON.stringify(response.tokenId), 7);
      // } else {
      //   setValidate((pre) => ({
      //     ...pre,
      //     errorMessage: "This user is not active",
      //   }));
      // }
      // console.log("Microsoft Sign In Response:", response);
      // Handle response and user authentication
    } catch (error) {
      console.error("Microsoft Sign In Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const { isLoading } = useContext(AppContext);
  return (
    <div className="h-full overflow-hidden">
      <div className="flex">
        <div className="sm:w-1/2 lg:w-2/3 sm:block hidden relative">
          <img
            className="loginImg h-screen -ml-3 object-cover"
            src="loginPicture.svg"
            alt="loginPicture"
          />
          <div className="absolute top-1/4 lg:left-28 left-12 pr-5 text-white flex flex-col gap-6">
            <label className="lg:text-4.5xl text-4xl lg:leading-12">
              Unleashing the power of AI
              <br /> with AI Chatbot
            </label>
            <label className="lg:text-2xl text-base font-medium">
              {/* Monitor, control and take actions from anywhere, anytime. */}
            </label>
          </div>
        </div>
        <form className="sm:w-1/2 lg:w-1/3 w-full px-10 md:px-16 sm:pt-24 pt-10 relative sm:mr-4">
          <button
            type="button"
            className="w-fit h-12 sm:hidden mb-10 flex items-center cursor-pointer"
          >
            <img src="apollo_logo.svg" className="w-fit" alt="LOGO" />
          </button>
          <label className="text-white sm:pt-0 text-xl font-medium">
            Hi, Welcome Back
          </label>
          <div className="pt-7 flex flex-col gap-2 h-full">
            {/* <label className="font-semibold text-white text-sm">Username</label>
            <input
              type="text"
              name="userName"
              placeholder="Enter Username"
              value={user.userName}
              onChange={handleChange}
              className={`${
                validate.userName === true
                  ? "border-red-800  bg-red-550"
                  : "border-blue-800 bg-white "
              } w-full h-14 placeholder-neutral-400 bg-white pl-5 border text-xs font-medium rounded-xl focus:outline-none`}
            ></input>
            {validate.userName && (
              <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 28 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 7.10278V11.1028"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 15.1028H11.4582"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Username must be entered
              </div>
            )}
            <label className="font-semibold text-white text-sm mt-1">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={user.password}
                onChange={handleChange}
                className={`${
                  validate.password === true || validate.passwordIncorrect
                    ? "border-red-600 bg-red-550 text-black"
                    : "border-blue-800 bg-white "
                } w-full h-14 placeholder-neutral-400 text-black pl-5 text-xs font-medium rounded-xl border focus:outline-none`}
              ></input>
              {validate.password && (
                <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 28 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                      stroke="#FF2828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.4482 7.10278V11.1028"
                      stroke="#FF2828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.4482 15.1028H11.4582"
                      stroke="#FF2828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Password must be entered
                </div>
              )}
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute right-6 top-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute right-6 top-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </button>
            </div>
            {validate.passwordIncorrect && user.password && (
              <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 28 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 7.10278V11.1028"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 15.1028H11.4582"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Invalid Username or Password
              </div>
            )} */}
            {validate.errorMessage && (
              <div className="text-xs font-normal flex gap-1 items-center text-red-600 mt-1">
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 28 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274Z"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 7.10278V11.1028"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4482 15.1028H11.4582"
                    stroke="#FF2828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {validate.errorMessage}
              </div>
            )}

            {/* <div className="flex mt-4 w-full justify-end items-center px-2">
              <button
                type="button"
                disabled
                onClick={() => navigate("/resetPassword")}
                className="text-sm disabled:cursor-not-allowed font-medium text-center w-fit hover:underlinen text-orange-600 mt-3 underline "
              >
                Forgot my Password!
              </button>
            </div>
            <div className="flex flex-col justify-center items-center">
              <button
                type="submit"
                className="bg-gradient-to-r flex justify-center items-center gap-2 from-red-800 disabled:opacity-40 to-orange-600 mt-4 text-white rounded-lg w-full h-14 font-semibold text-xs text-align-center"
                onClick={onLoginClick}
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                LOGIN
              </button>
            </div> */}

            <div className="flex flex-col justify-center gap-2 mt-2">
              <GoogleLogin
                className=" login-with-btn"
                clientId={Client_id}
                buttonText="Login with Google"
                onSuccess={responseGoogleSuccess}
                onFailure={responseGoogleFailure}
                cookiePolicy={"single_host_origin"}
                isSignedIn={false}
              />
              <button
                onClick={() => msSignIn()}
                type="button"
                className="login-with-btn ms-btn flex"
              >
                <img
                  src="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                  alt="Microsoft"
                />{" "}
                Login with Microsoft
              </button>
            </div>
            <div className="flex justify-center gap-5 md:gap-8 items-center mt-10 lg:mt-10 lg:pt-5 right-1/2 transform -translate-y-1/2 ">
              <label className="text-white text-xxs md:text-xs">
                Powered by{" "}
              </label>
              <img
                src="GamberaDynamicsLogo.svg"
                className="w-24 lg:w-28"
                alt="gd-rms_logo"
              />
            </div>
          </div>
        </form>
      </div>
      {authCtx.isLoading && (
        <div class="bg-neutral-950 fixed top-0 left-0 w-full min-h-screen flex justify-center items-center">
          <div class="flex flex-col gap-4 min-h-screen w-full items-center justify-center bg-neutral-950">
            <div class="flex h-14 w-14 items-center justify-center rounded-full bg-gradient-to-tr from-indigo-500 to-pink-500 animate-spin">
              <div class="h-9 w-9 rounded-full bg-neutral-950"></div>
            </div>
            <div className="text-white text-lg font-semibold">
              Checking Authentication...
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
