import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import ProgressBar from "./ProgressBar";
import { toast } from "react-toastify";
import { BASE_PY_URL, BASE_URL, sanityClient } from "../Constants";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import AppContext from "../AppContext.js";

function HrChatbot() {
  const scrollViewRef = useRef(null);
  const { profile } = useContext(AppContext);
  const [chatMessages, setChatMessages] = useState([]);
  const [sanityData, setSanityData] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [input, setInput] = useState("");
  const [url, setUrl] = useState("");
  const [respondLoader, setRespondLoader] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(false);
  const [urls, setUrls] = useState([]);
  const [pdfPath, setPdfPath] = useState([]);
  const [webPageUrls, setWebPageUrls] = useState([]);
  const [urlsSubmitted, setUrlsSubmitted] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [progress, setProgress] = useState({ value: 0, message: null });
  const [selectedDocument, setSelectedDocument] = useState();
  const [skipped, setSkipped] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAi, selectAiModel] = useState();
  const [nodes, setNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [processingUrl, setProcessingUrl] = useState();
  const [socketInstansce, setSocketInstance] = useState();
  // const [sessionId, setSessioId] = useState();
  const [socketLoading, setSocketLoading] = useState(false);
  const socketLoadedRef = useRef(false);
  const sessionRef = useRef(null);

  useEffect(() => {
    fetchData();
    if (!sessionRef.current) sessionRef.current = Date.now();
  }, []);

  const fetchData = async () => {
    const query = `*[_type == "chatbot"][0]{
    hrChatbot{
      'image':image.asset->url,
      title,
      description
    }
  }
`;
    const resData = await sanityClient.fetch(query);
    setSanityData(resData);
    console.log(resData, "bbbbbbbbbbbbbbbbb");
  };
  const changeHandler = async (event) => {
    setUploadLoader(true);
    const file = event.target.files[0];

    // Check if the file type is PDF
    if (file.type !== "application/pdf") {
      setUploadLoader(false);
      return setError("Uploaded file is not pdf.");
    }
    // Check if the file size exceeds the maximum allowed size (15MB)
    if (file.size > 15 * 1000 * 1024) {
      setUploadLoader(false);
      return setError("Maximum file size allowed is 15MB.");
    }

    const formData = new FormData();
    formData.append("files", file);

    // Continue with the upload process
    try {
      const res = await axios.post(
        `${BASE_URL}/DocumentUpload/UploadDocument`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (res.status === 200) {
        setSelectedDocument({
          name: file.name,
          size: file.size,
          type: file.type,
          docId: res.data[0].docId,
        });
        setPdfViewer(true);
        setSkipped(true);
        setPdfPath((pre) => [
          ...pre,
          {
            name: file.name,
            size: file.size,
            type: file.type,
            docId: res.data[0].docId,
          },
        ]);
        setChatMessages((pre) => [
          ...pre,
          { user: false, message: "Hi, how can I help you?" },
        ]);
      } else {
        toast.error("Some error occurred when uploading the document!");
      }
    } catch (error) {
      console.error("Error uploading document:", error.message);
      toast.error("Some error occurred when uploading the document!");
    }

    setUploadLoader(false);
  };

  // const testHelloWorld = async (event) => {
  //   event.preventDefault();
  //   setPdfPath([]);
  //   setUploadLoader(true);

  //   const apiUrl = `${BASE_PY_URL}/api/hell_world`;
  //   const response = await axios.post(apiUrl);
  //   try {
  //     const interval = processProgress();
  //     const requestBody = { url };
  //     const response = await axios.post(apiUrl, requestBody);
  //     console.log(response);
  //     if (response.status === 200) {
  //       setSkipped(true);
  //       setUrls((pre) => [...pre, url]);
  //       setUrl("");
  //       console.log("url uploaded successfully");
  //       setProgress(100);
  //       // setChatMessages((pre) => [
  //       //   ...pre,
  //       //   { user: false, message: "Hi, how can I help you?" },
  //       // ]);
  //     } else {
  //       clearInterval(interval);
  //       console.error("Error uploading URL:", response.statusText);
  //       toast.error("Some error occurred when uploading the URL!");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading PDF:", error.message);
  //     toast.error("Some error occurred when uploading the PDF!");
  //   }

  //   setUploadLoader(false);
  // };

  const buildTree = (urls, startUrl) => {
    const tree = [
      {
        key: startUrl,
        label: "Home",
        data: startUrl,
        children: [],
        loading: true,
        isKeyUrl: true,
      },
    ];

    const map = {
      [startUrl]: tree[0],
    };

    urls.forEach((url) => {
      const path = new URL(url).pathname; // Extract path excluding protocol
      const { protocol, hostname } = new URL(url);

      const parts = path.split("/").filter(Boolean);
      let currentLevel = map[startUrl].children;

      parts.forEach((part, index) => {
        const fullPath = parts.slice(0, index + 1).join("/");
        const key = `${protocol}//${hostname}/${fullPath}`; // Create a unique key
        const isKeyUrl = urls.includes(key);
        if (!map[fullPath]) {
          const newNode = {
            key: key,
            label: part,
            data: key,
            children: [],
            loading: false,
            isKeyUrl,
          };
          map[fullPath] = newNode;
          currentLevel.push(newNode);
        }
        currentLevel = map[fullPath].children;
      });
    });

    setExpandedKeys({ [startUrl]: true });
    return tree;
  };

  const getWebUrls = async (event) => {
    event.preventDefault();
    setPdfPath([]);
    setUploadLoader(true);
    setDisableButton(true);
    console.log(profile);

    const apiUrl = `${BASE_PY_URL}/api/crawl-url`;
    const requestBody = {
      url: url.endsWith("/") ? url.slice(0, -1) : url,
      session_id: sessionRef.current,
      user_key: profile.email,
      crawl_if_missing: true,
    };

    try {
      const interval = processProgress();
      const response = await axios.post(apiUrl, requestBody);

      if (response.status === 200) {
        if (response.data.success) {
          setDisableButton(false);
          setUrlsSubmitted(true);
          setSkipped(true);
          setUrls((pre) => [...pre, requestBody.url]);
          setUrl("");
          setProgress((prev) => ({ ...prev, value: 100 }));
        } else {
          toast.warning(
            "The data from the specified website has not been captured yet. Please check back later for updates."
          );
        }
      } else {
        console.error("Error uploading URL:", response.statusText);
        toast.error("Some error occurred while processing!");
      }

      clearInterval(interval);
    } catch (error) {
      console.error("Error uploading URLs:", error.message);
      toast.error("Some error occurred while processing!");
    }

    setUploadLoader(false);
    setDisableButton(false);
  };

  const uploadPdf = async (event) => {
    setUrls([]);
    const file = event.target.files[0];

    // Check if the file type is PDF
    if (file.type !== "application/pdf") {
      return setError("Uploaded file is not PDF.");
    }

    // Check if the file size exceeds the maximum allowed size (15MB)
    if (file.size > 15 * 1000 * 1024) {
      return setError("Maximum file size allowed is 15MB.");
    }

    const apiUrl = `${BASE_PY_URL}/upload?overwrite=${
      pdfPath.length === 0 ? true : false
    }&sessionId=${sessionRef.current}`;

    const formData = new FormData();
    formData.append("pdf", file);

    // Continue with the upload process
    try {
      const interval = processProgress(); // Assuming processProgress() handles the progress bar
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("PDF uploaded successfully");
        setProgress((pre) => ({ ...pre, value: 100 }));

        // Optionally update UI or state upon successful upload
        // setChatMessages((prevMessages) => [
        //   ...prevMessages,
        //   { user: false, message: "Hi, how can I help you?" },
        // ]);
      } else {
        clearInterval(interval);
        console.error("Error uploading PDF:", response.statusText);
        toast.error("Some error occurred when uploading the PDF!");
      }
    } catch (error) {
      console.error("Error uploading PDF:", error.message);
      toast.error("Some error occurred when uploading the PDF!");
    }

    setUploadLoader(false);
  };

  // const onTextSubmit = async () => {
  //   setDisableButton(true);
  //   setChatMessages((pre) => [...pre, { user: true, message: input }]);
  //   scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight + 100;
  //   const request = { prompt: input };
  //   setInput("");
  //   setRespondLoader(true);
  //   const res = await axios.post(`${BASE_URL}/api/askQuestions`, request);
  //   if (res.status === 200) {
  //     setChatMessages((pre) => [...pre, { user: false, message: res.data }]);
  //     scrollViewRef.current.scrollTop =
  //       scrollViewRef.current.scrollHeight + 100;
  //     setRespondLoader(false);
  //     setDisableButton(false);
  //   }
  // };

  const handleChatQuery = async () => {
    try {
      setDisableButton(true);
      setChatMessages((pre) => [
        ...pre,
        { user: true, message: input },
        { user: false, message: "", loading: true },
      ]);
      setInput("");

      // Scroll after user input is added
      setTimeout(() => {
        const newListItem = document.getElementById("respondLoader");
        if (newListItem) {
          newListItem.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        scrollViewRef.current.scrollTop =
          scrollViewRef.current.scrollHeight + 100;
      }, 0);

      setRespondLoader(true);

      const response = await axios.post(`${BASE_PY_URL}/api/query`, {
        query: input,
        user_key: profile.email,
        root_url: urls.length > 0 ? urls[0] : "",
      });

      if (response.status === 200) {
        setChatMessages((pre) =>
          pre.map((x) =>
            x.loading
              ? {
                  user: false,
                  message: response.data.response.includes(
                    "I don't know" || "I don't have that information"
                  )
                    ? "I dont't have the information about that. Please check the website for more details."
                    : response.data.response,
                }
              : x
          )
        );

        // Scroll after receiving the response
        setTimeout(() => {
          const newListItems = document.getElementById("respond");
          if (newListItems) {
            newListItems.scrollIntoView({ behavior: "smooth", block: "end" });
          }
          scrollViewRef.current.scrollTop =
            scrollViewRef.current.scrollHeight + 100;
        }, 0);

        setRespondLoader(false);
        setDisableButton(false);
      }
    } catch (error) {
      setDisableButton(false);
      setRespondLoader(false);
      console.error("Error querying chat:", error.message);
    }
  };

  const processProgress = async () => {
    setProgress({ message: null, value: 0 }); // Reset progress
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        // Increment progress until it reaches 100
        if (prevProgress < 70) {
          return { prevProgress, value: prevProgress.value + 1 };
        } else {
          clearInterval(intervalId);
          return { ...prevProgress, value: 70 };
        }
      });
    }, 50);
    return intervalId; // Increment every second (adjust as needed)
  };

  const onCloseClick = () => {
    selectAiModel(null);
    setPdfPath([]);
    setWebPageUrls([]);
    setNodes([]);
    setUrls([]);
    setDisableButton(false);
    setUrlsSubmitted(false);
    setRespondLoader(false);
    setSelectedDocument(null);
    setPdfViewer(false);
    setSkipped(false);
    setChatMessages([]);
  };

  const onTreeSelectionChange = (e) => {
    let filteredKeys = {};
    console.log(e);
    for (const key in e.value) {
      if (e.value[key].checked) {
        filteredKeys[key] = e.value[key];
      }
    }
    console.log(filteredKeys);
    setSelectedKeys(filteredKeys);
  };

  const loadSocket = async () => {
    setSocketLoading(true);
    if (!sessionRef.current & !socketLoadedRef.current) {
      socketLoadedRef.current = true; // Prevent further calls

      const generatedSessionId = uuidv4(); // Generate a unique session ID
      sessionRef.current = generatedSessionId;

      const socket = io(BASE_PY_URL, {
        query: { sessionId: generatedSessionId },
        reconnection: true,
        reconnectionAttempts: 3,
      });

      setSocketInstance(socket);

      socket.on("connect", () => {
        console.log("Connected to the WebSocket server");
        socket.emit("join", { session_id: generatedSessionId }); // Join the room with the session ID
      });

      socket.on("progress", (data) => {
        console.log("Progress:", data);
        setProgress((prevProgress) => ({
          ...prevProgress,
          message: data,
        }));
      });
    }
  };

  // console.log(socketLoading, sessionRef);
  // useEffect(() => {
  //   if (!socketLoadedRef.current) {
  //     loadSocket();
  //   }
  // }, []);

  const deletePdf = (index) => {
    setPdfPath((prev) => prev.filter((_, i) => i !== index));
  };

  function bytesToMB(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  const nodeTemplate = (node, options) => {
    return (
      <div className={`flex justify-between w-full ${options.className}`}>
        <p
          href={node.data}
          className="text-700 hover:text-primary text-xs font-medium"
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.label}
        </p>
        {node.isKeyUrl && (
          <a title={node.data} target="_blank" href={node.data}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
        )}
      </div>
    );
  };

  return (
    <main
      className={`flex md:flex-row flex-col relative md:h-[88vh] h-full md:gap-0 gap-5 text-black bg-white md:py-10 py-2 md:px-6  px-6 justify-between `}
    >
      {pdfViewer ? (
        <div className="md:w-1/3  border-2 border-neutral-200 md:ml-10 md:mr-5 h-full rounded-lg">
          <div className="flex flex-col bg-neutral-100 w-full py-3">
            <div className="flex gap-9  h-fit w-full items-center justify-between px-6">
              <div className="flex gap-2 items-center">
                <img alt="" src="/document-icon.svg" height={32} width={25} />
                <label className="text-sm text-zinc-500 font-medium">
                  {selectedDocument?.name}
                </label>
              </div>
              <div className="flex gap-2">
                <label className="text-sm text-zinc-500 font-medium">
                  {bytesToMB(selectedDocument?.size)}&nbsp;MB
                </label>
                <button onClick={() => setPdfViewer(false)}>
                  <img alt="" src="/close-icon.svg" height={20} width={20} />
                </button>
              </div>
            </div>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div
              style={{
                height: "70vh", // Set the height of the container
                width: "100%",
                overflowY: "auto", // Enable vertical scrolling
              }}
            >
              <Viewer
                enableSmoothScroll
                fileUrl={`${BASE_URL}/DocumentUpload/documents/loadfile?id=${selectedDocument?.docId}`}
              />
            </div>
          </Worker>
        </div>
      ) : null}
      <div className="flex flex-col h-full md:justify-between justify-center items-center md:w-full w-full md:mx-auto">
        {skipped == false ? (
          <>
            <div className="flex flex-col items-center gap-3 pb-4">
              <button
                onDoubleClick={() => {
                  setSkipped(true);
                }}
              >
                <img
                  width={65}
                  height={65}
                  src={sanityData.hrChatbot?.image}
                  alt="Logo"
                  className="mr-2"
                />
              </button>
              <div className="text-center flex flex-col items-center">
                <div className="text-black text-xl font-bold leading-normal">
                  {sanityData.hrChatbot?.title
                    .split(" ")
                    .slice(0, -2)
                    .join(" ")}
                  <span className="text-blue-500">
                    &nbsp;
                    {sanityData.hrChatbot?.title.split(" ").slice(-2).join(" ")}
                  </span>
                </div>
                <div className="text-[#757691] text-center text-sm w-3/5 leading-6">
                  {/* {sanityData.hrChatbot?.description} */}
                  {error ? (
                    <div className="flex gap-10 h-full justify-center items-start">
                      <div className="text-red-700 text-center text-sm w-full leading-6">
                        {<div className="flex flex-col gap-5">{error}</div>}
                      </div>
                    </div>
                  ) : (
                    <div>{!selectedAi ? "Select an AI model" : ""}</div>
                  )}
                </div>
              </div>
              {selectedAi ? (
                <div className="flex flex-col items-center mb-12 border-dashed border-2">
                  <div className="relative">
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadPdf(e);
                        setUrlsSubmitted(true);
                        setNodes([]);
                        changeHandler(e);
                      }}
                      disabled={uploadLoader}
                      className="absolute top-0 left-0 h-full w-full border-none bg-transparent opacity-0"
                    />
                    <div className="flex px-28 rounded-md flex-col justify-center items-center md:space-y-6 pt-10">
                      <div className="bg-blue-200 rounded-full w-28 h-28 flex justify-center items-center">
                        <div className="bg-blue-500 rounded-full w-20 h-20 flex justify-center items-center">
                          <img
                            width={45}
                            height={45}
                            src="/docUpload.png"
                            alt="doc"
                            className=""
                          />
                        </div>
                      </div>
                      {uploadLoader ? (
                        <div className="w-full flex flex-col items-center gap-1 pb-8">
                          <div className="w-40">
                            <ProgressBar percentage={progress.value} />
                          </div>
                          <div className="text-xxs text-slate-500 w-80 text-center h-8">
                            {progress.message?.url
                              ? `Following url : ${progress.message?.url}`
                              : "Processing"}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center space-y-8">
                          <div className="text-center flex flex-col items-center">
                            <div className="text-[#757691] text-center text-sm leading-6">
                              Drag & Drop or
                              <span className="text-blue-500 underline mx-2">
                                browse
                              </span>
                              Document
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!uploadLoader && (
                    <div className="flex flex-col items-center w-full">
                      <label className="py-5 text-black">OR</label>
                      <div className="flex text-[#757691] w-2/3 flex-col gap-3 items-center text-sm justify-start pb-8">
                        <label>Enter Web URL</label>
                        <form
                          onSubmit={(e) => getWebUrls(e)}
                          class="relative w-full md:pt-0 pt-5"
                        >
                          <input
                            type="search"
                            id="default-search"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            class="block px-2 py-2 h-9 pr-10 text-xs w-full text-center placeholder:text-center text-black border border-gray-300 rounded-full bg-gray-50 focus:ring-0 focus:outline-none"
                            placeholder="please type your URL"
                          />
                          <button
                            type="submit"
                            disabled={disableButton}
                            onClick={(e) => getWebUrls(e)}
                            class="text-white absolute h-7 w-7 disabled:opacity-30 end-1 bottom-1 bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-gray-800 font-medium rounded-full text-sm p-2"
                          >
                            <img
                              alt=""
                              src="/chatUpload.png"
                              height={24}
                              width={24}
                            />
                          </button>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex gap-2">
                  <button
                    onClick={() => selectAiModel("cloudAi")}
                    className="bg-blue-500 border border-blue-500 text-white hover:bg-white px-4 py-2 rounded hover:border-blue-600 hover:text-blue-600"
                  >
                    Cloud AI
                  </button>
                  <button
                    onClick={() => selectAiModel("premiseAi")}
                    className="bg-blue-500 border border-blue-500 text-white hover:bg-white px-4 py-2 rounded hover:border-blue-600 hover:text-blue-600"
                  >
                    On Premise AI
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex flex-col space-y-8 transition-all ease-in-out duration-200 h-full max-h-[65vh] min-h-96 w-full">
            <div className="flex justify-between items-start w-full gap-5 ">
              <div className="flex flex-col rounded-xl bg-neutral-100 py-3 gap-3">
                {urls.map((item, index) => (
                  <div
                    className="flex gap-9  h-fit w-fit items-center md:px-6 px-3"
                    key={index}
                  >
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="blue"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                        />
                      </svg>
                      <a
                        href={item}
                        target="_blank"
                        className="md:text-sm text-xs text-blue-500 underline font-medium"
                      >
                        {item}
                      </a>
                    </div>
                  </div>
                ))}
                {pdfPath.map((item, index) => (
                  <div
                    className="flex gap-9  h-fit w-fit items-center md:px-6 px-3"
                    key={index}
                  >
                    <div className="flex gap-2 items-center">
                      <img
                        alt=""
                        src="/document-icon.svg"
                        height={32}
                        width={25}
                      />
                      <label className="md:text-sm text-xs text-zinc-500 font-medium">
                        {item?.name}
                      </label>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setPdfViewer(true);
                          setSelectedDocument(item);
                        }}
                      >
                        <img
                          alt=""
                          src="/edit-icon.svg"
                          height={20}
                          width={20}
                        />
                      </button>
                      <button onClick={() => deletePdf(index)}>
                        <img
                          alt=""
                          src="/delete-icon.svg"
                          height={20}
                          width={20}
                        />
                      </button>{" "}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex lg:gap-5 gap-2">
                <button
                  type="button"
                  onClick={onCloseClick}
                  className="text-blue-600 border border-blue-600 rounded-xl lg:w-24 p-3 h-fit md:text-base text-xs"
                >
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.0"
                    stroke="blue"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg> */}
                  Back
                </button>
                {urls.length === 0 && (
                  <div className="relative">
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadPdf(e);
                        changeHandler(e);
                      }}
                      disabled={uploadLoader}
                      className="absolute top-0 left-0 h-full w-full border-none bg-transparent opacity-0"
                    />

                    {uploadLoader ? (
                      <div className="w-full flex flex-col justify-center items-center gap-1 border border-blue-600 rounded-xl p-3 h-fit md:w-fit">
                        <ProgressBar percentage={progress.value} />
                        <div className="text-xs text-slate-500">
                          {progress.message?.url
                            ? `Following url : ${progress.message?.url}`
                            : "Processing"}
                        </div>
                      </div>
                    ) : (
                      <div className="text-blue-600 border border-blue-600 rounded-xl p-3 h-fit  md:w-full w-20 md:text-base text-xs">
                        Upload More Documents
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              ref={scrollViewRef}
              className={`${
                !urlsSubmitted ? "py-0" : " py-6"
              } flex flex-col w-full gap-7 overflow-y-auto scroll h-full px-5`}
            >
              {/* {!urlsSubmitted && (
                <div
                  className={`md:w-full relative border-2 overflow-hidden border-neutral-200 mx-auto h-fit rounded-lg`}
                >
                  {uploadLoader && pdfPath.length === 0 && nodes.length > 0 && (
                    <div>
                      <InfiniteProgress
                        mode="indeterminate"
                        style={{ height: "6px" }}
                      ></InfiniteProgress>
                      <span className="text-xs mx-10 transition-all duration-200 ease-in-out">
                        {progress.message &&
                        progress.message?.status === "running" ? (
                          <div className="flex gap-2 mx-10 items-center transition-all duration-200 ease-in-out">
                            <div class="flex space-x-1 justify-center items-center h-fit bg-white">
                              <span class="sr-only">Loading...</span>
                              <div class="h-3 w-3 bg-gray-200 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                              <div class="h-3 w-3 bg-gray-200 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                              <div class="h-3 w-3 bg-gray-200 rounded-full animate-bounce"></div>
                            </div>
                            <p className="mb-1">
                              Crawling URL:{" "}
                              <a
                                href={progress.message.url}
                                target="_blank"
                                for="checked-checkbox"
                                class=" text-xs font-medium text-blue-500 underline dark:text-gray-300"
                              >
                                {progress.message.url}
                              </a>
                              ...
                            </p>
                          </div>
                        ) : progress.message?.status === "success" ? (
                          <div className="flex gap-2 mx-10 items-center transition-all duration-200 ease-in-out">
                            <img src="greentick.svg" className="h-4" />
                            Successfully extracted text from URL:
                            <a
                              href={progress.message.url}
                              target="_blank"
                              for="checked-checkbox"
                              class=" text-xs font-medium text-blue-500 underline dark:text-gray-300"
                            >
                              {progress.message.url}
                            </a>
                          </div>
                        ) : (
                          <div className="flex gap-2 mx-10 items-center transition-all duration-200 ease-in-out">
                            <div class="flex space-x-1 justify-center items-center h-fit bg-white">
                              <span class="sr-only">Loading...</span>
                              <div class="h-3 w-3 bg-gray-200 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                              <div class="h-3 w-3 bg-gray-200 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                              <div class="h-3 w-3 bg-gray-200 rounded-full animate-bounce"></div>
                            </div>

                            <p className="mb-1">Processing...</p>
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  <TreeComponent
                    nodes={nodes}
                    uploadLoader={uploadLoader}
                    pdfPath={pdfPath}
                    selectedKeys={selectedKeys}
                    setSelectedKeys={setSelectedKeys}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    nodeTemplate={nodeTemplate}
                    onSelectionChange={onTreeSelectionChange}
                  />
                  <div className="sticky bottom-0 bg-white w-full flex flex-col py-4 px-3">
                    <button
                      onClick={(e) =>
                        !urlsSubmitted ? uploadUrls(e) : setUrlsSubmitted(false)
                      }
                      disabled={uploadLoader}
                      className="bg-blue-500 w-full disabled:opacity-40 px-3 py-1 text-sm self-end text-white font-medium rounded-md"
                    >
                      {urlsSubmitted ? "Edit" : "Process"}
                    </button>
                  </div>
                </div>
              )} */}
              {chatMessages.map((x, index) => (
                <div
                  className={`flex gap-3 transition-all ease-in-out duration-200 ${
                    x.user ? "flex-row-reverse" : "flex-row"
                  }`}
                  id={
                    index === chatMessages.length - 1 && !x.user
                      ? "respond"
                      : ""
                  }
                >
                  <div
                    className={`w-12 h-12 rounded-full border shrink-0 flex justify-center items-center ${
                      x.user ? "border-stone-300" : "bg-blue-600"
                    }`}
                  >
                    {x.user ? (
                      <img alt="" src="/userIcon.png" height={24} width={24} />
                    ) : (
                      <img
                        alt=""
                        src="/chatbot-icon.svg"
                        height={26}
                        width={26}
                      />
                    )}
                  </div>
                  <div className={`flex flex-col gap-2 `}>
                    <div
                      className={`font-semibold text-sm text-black ${
                        x.user ? "text-right" : "text-left"
                      }`}
                    >
                      {x.user ? "You" : "Chatbot"}
                    </div>
                    {x.loading ? (
                      <div className="text-neutral-500 flex items-center gap-1 animate-pulse text-sm font-medium max-w-96 text-left">
                        {[...Array(6)].map((_, index) => (
                          <div
                            key={index}
                            class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-3"
                          ></div>
                        ))}
                      </div>
                    ) : (
                      <div
                        className={`text-neutral-500 text-sm font-medium max-w-96 ${
                          x.user ? "text-right" : "text-left"
                        }`}
                      >
                        <p style={{ whiteSpace: "pre-wrap" }}>{x.message}</p>
                        {/* <SanitizedHTML html={x.message} />, */}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {/* {respondLoader ? (
                <div
                  className="flex gap-3 transition-all ease-in-out duration-200"
                  id="respondLoader"
                >
                  <div className="w-12 h-12 rounded-full border  flex justify-center items-center bg-blue-600">
                    <img
                      alt=""
                      src="/chatbot-icon.svg"
                      height={26}
                      width={26}
                    />
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <div className="font-semibold text-sm text-black text-left">
                      Chatbot
                    </div>
                    <div className="text-neutral-500 flex items-center gap-1 animate-pulse text-sm font-medium max-w-96 text-left">
                      {[...Array(6)].map((_, index) => (
                        <div
                          key={index}
                          class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-3"
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        )}
        {(pdfPath.length > 0 || urls.length > 0) && (
          <form onSubmit={handleChatQuery} class="relative w-full md:pt-0 pt-5">
            <input
              type="search"
              id="default-search"
              value={input}
              disabled={!urlsSubmitted}
              onChange={(e) => setInput(e.target.value)}
              class="block w-full p-4 text-sm text-black border border-gray-300 rounded-full bg-gray-50 focus:ring-0 pr-16 focus:outline-none"
              placeholder="please type your query"
            />
            <button
              type="submit"
              disabled={disableButton}
              onClick={handleChatQuery}
              class="text-white absolute disabled:opacity-30 end-2.5 bottom-2 bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-gray-800 font-medium rounded-full text-sm p-2"
            >
              <img alt="" src="/chatUpload.png" height={24} width={24} />
            </button>
          </form>
        )}
        {pdfPath.length !== 0 && (
          <label className="text-neutral-500 font-medium text-sm w-full pl-5 mt-1 transition-all ease-in-out duration-200">
            Chatbot will can provide answers to queriers with thehelp of the
            document uploaded
          </label>
        )}
      </div>
    </main>
  );
}

export default HrChatbot;
